@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.footerWrapper {
  @include media.mobile {
    position: relative;
  }

  @include flex-horizontal-center;

  background-color: theme.$primary-background-color;

  .footer {
    @include media.tablet-narrow {
      width: 100%;
      height: auto;
    }

    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: theme.$primary-background-color;

    .columnsContainer {
      @include media.mobile {
        flex-direction: column;
        align-items: center;
        height: auto;
        padding-top: 50px;
        text-align: center;
      }

      @include media.tablet-wide {
        flex-direction: column;
        gap: 20px;
        align-items: center;
        height: auto;
        padding-top: 50px;
        text-align: center;
      }

      display: flex;
      gap: 100px;
      justify-content: center;
      height: 384px;
      padding-top: 80px;

      .column {
        @include media.mobile {
          width: theme.$mobile-default-page-width !important;
        }

        display: flex;
        flex-direction: column;

        .title {
          margin-bottom: 16px;
          font-family: theme.$main-font-family;
          font-weight: 600;
        }

        .icons {
          display: flex;

          @include media.mobile {
            width: fit-content;
            margin: auto;
          }
        }

        .customIcon {
          margin-right: 16px;
          color: theme.$branding-font-color;
        }

        .link {
          padding: 10px 0;

          .content {
            font-size: 16px;
            line-height: 1;
            color: #a8a8a8;

            &:hover {
              color: theme.$branding-font-color;
            }
          }
        }
      }

      .socialColumn {
        width: 164px;

        .title {
          @include media.mobile {
            margin-bottom: 26px;
          }
        }
      }

      .logoColumn {
        @include media.tablet-wide {
          width: 90%;
          margin-bottom: 4px;
        }

        .teaserText {
          margin-top: 16px;
          color: #a8a8a8;

          @include media.desktop-up {
            max-width: 160px;
          }

          .link {
            color: theme.$branding-font-color;
          }
        }

        .logoContainer {
          @include media.tablet-wide {
            @include flex-all-center;

            margin-bottom: 40px;
          }

          display: flex;
          margin-bottom: 40px;

          .logo {
            width: 66px;
            height: 66px;
            margin-right: 8px;
            border-radius: 50%;
          }

          .text {
            .word {
              font-size: 20px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
