@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.courseCardWrapper {
  @include media.desktop-up {
    width: 80%;
    height: auto;
  }

  @include media.desktop {
    width: 50%;
    margin-bottom: 40px;
  }

  @include media.tablet-narrow {
    width: 60%;
  }

  @include media.mobile {
    width: theme.$mobile-default-page-width;
    height: auto;
    margin-bottom: 64px;
  }

  @include flex-horizontal-center;

  .courseCard {
    @include media.mobile {
      width: 100%;
    }

    @include media.widescreen {
      width: 76%;
    }

    width: 90%;
    max-width: 360px;
    height: auto;
    background-color: #20232d;
    border-radius: 24px;
  }

  .image {
    width: 100%;
    height: 268px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .infoColumn {
    height: 252px;
    color: #fff;
    background-color: #20232d;
    border-radius: 10px;
  }

  .descriptionAndLink {
    padding: 20px 30px 10px;
  }

  .category {
    margin-bottom: 32px;
    font-size: 22px;
    font-weight: 700;
  }

  .text {
    height: 60px;
    margin-bottom: 32px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    line-height: 128%;
  }

  .externalLink {
    color: theme.$branding-font-color;
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border: #fff solid 1px;
    border-radius: 8px;

    &:hover {
      color: theme.$branding-font-color;
      border: theme.$branding-font-color solid 1px;
    }
  }
}
