@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.dropdownMenu {
  @include z-index(dropdownmenu);

  @include media.tablet-narrow {
    background-color: #3b3e4b;
  }

  display: flex;
  flex-direction: column;
  color: #fff;
  transition: all 2s linear;

  .listItem {
    @include media.mobile {
      font-weight: 400;
      border-bottom: 1px solid #30343f;
    }

    padding: 14px 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 1px solid #3b3e4b;

    &:hover {
      color: theme.$branding-font-color;
    }
  }

  .listItem:last-child {
    border-bottom: none;
  }
}
