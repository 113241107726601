@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.testimonialCarouselWrapper {
  width: 100%;
  min-width: 100px;
  height: fit-content;
  min-height: 100px;
  padding: 60px;
  text-align: center;
  background: theme.$light-background-color;

  @include media.tablet-wide {
    height: auto;
    padding: 60px 0 0;
  }

  @include media.mobile {
    height: auto;
    padding: 0;
  }

  .title {
    width: 100%;
    color: theme.$primary-font-color;
    text-align: center;
    user-select: none;
    -webkit-text-select: none;

    @include media.mobile {
      @include flex-all-center;

      margin: 46px 0 26px;
    }

    @include standard-page-title;
  }

  .textContainerWrapper {
    display: flex;
    width: 40% !important;
    margin: 60px 0 40px;

    @include media.mobile {
      margin: 0;
    }
  }

  .textContainer {
    @include media.tablet-wide {
      width: 90%;
      margin: auto;
    }

    @include media.mobile {
      flex-direction: column;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    -webkit-text-select: none;
  }

  .image {
    @include media.tablet-wide {
      width: 100%;
      height: calc(0.5 * 100vw);
      border-radius: 8px;
    }

    @include media.mobile {
      width: 100%;
      height: calc(0.67 * 100vw);
      border-radius: 8px;
    }

    width: 460px;
    height: 420px;
    user-select: none;
    border-radius: 16px;
    -webkit-text-select: none;
  }

  .content {
    @include media.tablet-wide {
      width: 50%;
      margin-right: 40px;
    }

    @include media.mobile {
      order: 2;
      width: theme.$mobile-default-page-width;
      margin-right: 0;
    }

    @include flex-column-horizontal-center;

    width: 462px;
    height: fit-content;
    margin-right: 120px;
  }

  .text {
    @include media.mobile {
      padding: 20px 0 0;
      margin-bottom: 20px;
    }

    @include standard-page-content;

    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 40px 0;
  }

  .testimonialPerson {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    color: theme.$branding-font-color;
    text-align: left;
  }

  .iconContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 42px;
    font-weight: 800;
    line-height: 1.2;
    color: #fff;
  }

  .customIcon {
    color: theme.$branding-font-color;
  }
}
