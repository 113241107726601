@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.coursesPageWrapper {
  .coursesPage {
    width: 100%;
    background-color: theme.$secondary-background-color;
    scroll-behavior: smooth;

    .upperSection {
      position: relative;
      width: 100%;
      height: 100vh;

      @include media.short {
        height: 100vh;
      }

      @include flex-all-center;

      .backgroundImage {
        @include background-image;
      }

      .text {
        @include z-index(bannerText);

        @include media.widescreen {
          margin: 220px 0;
        }

        @include media.tablet-wide {
          display: flex;
          flex-direction: column;
          width: 84%;
          padding-top: 30px;
          padding-bottom: 30px;
          margin: 140px 0;
        }

        width: 80%;
        margin: 160px 0;

        .title {
          margin-bottom: 40px;
          font-weight: 400;
          color: #fff;
          opacity: 0.8;

          @include media.tablet-wide {
            font-size: 32px !important;
          }

          @include media.mobile {
            width: 100%;
            margin-bottom: 60px;
          }

          @include standard-page-title;
        }

        .content {
          @include media.widescreen {
            width: 30%;
          }

          @include media.mobile {
            width: 100%;
          }

          @include standard-page-content;

          width: 80%;
          color: #fff;
        }
      }
    }
  }
}
