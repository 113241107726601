$primary-font-color: #000;
$branding-font-color: rgb(178 251 178);
$primary-background-color: #fff;
$secondary-background-color: #12141d;
$light-background-color: #f4f4f4;
$background-color-contact-button: #2f323f;
$option-row-background-color: rgb(178 251 178);
$main-font-family: arial, sans-serif;
$purchase-button-background: rgb(181 207 170);
$main-box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
  0 1px 5px 0 rgb(0 0 0 / 12%);
$light-box-shadow: 0 1px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
  0 1px 2px 0 rgb(0 0 0 / 12%);
$form-background: #f4f4f4;
$z-index: (
  whatsappbuttonposition: 500,
  bannertext: 300,
  mobilemenu: 9999,
  dropdownmenu: 700,
  videobanner: 1,
);
$min-vh-page-wrapper: 65vh;
$default-page-width: 80%;
$mobile-default-page-width: 90%;
