@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.pageNotFoundWrapper {
  display: flex;
  justify-content: center;
  min-height: theme.$min-vh-page-wrapper;
  background-color: theme.$secondary-background-color;

  .pageNotFound {
    @include media.mobile {
      margin-top: 60px;
    }

    @include media.desktop {
      margin: 120px 0;
    }

    @include media.widescreen {
      margin: 12% 0;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .httpErrorNumber {
    @include media.mobile {
      font-size: 86px;
      font-weight: 700;
      letter-spacing: 4px;
    }

    font-size: 100px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 4px;
  }

  .httpErrorText {
    @include media.mobile {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 4px;
    }

    margin-bottom: 60px;
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 4px;
  }

  .text {
    @include media.mobile {
      width: 80%;
    }

    width: 760px;
    margin-bottom: 36px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.85;
    color: #ddd;
    text-align: center;
  }

  .button {
    @include media.mobile {
      width: 80% !important;
      margin-bottom: 131px !important;
      border-radius: 8px !important;
    }

    @include main-button;

    font-weight: 400;
    color: #ed068a;

    &:hover {
      color: #000;
    }
  }
}
