@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.coursesCardTeaserWrapper {
  @include flex-horizontal-center;

  width: 100%;

  .coursesCardTeaser {
    @include media.tablet-wide {
      flex-direction: column;
      width: 100%;

      :last-child {
        margin-bottom: 0;
      }
    }

    @include media.desktop {
      width: 100%;
    }

    @include media.widescreen {
      width: 80%;

      :last-child {
        margin-bottom: 0;
      }
    }

    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: fit-content;
    background-color: theme.$primary-background-color;
  }
}
