@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.navbarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: theme.$primary-background-color;

  @include media.mobile {
    display: block;
    width: 100%;
  }

  .word {
    font-weight: 400;
  }

  .menuWrapper {
    @include media.tablet-narrow {
      display: flex;
    }

    display: none;
  }

  .navbar {
    @include media.mobile {
      width: 100%;
      height: 90px;
    }

    @include media.tablet-wide {
      width: 94%;
      height: 90px;
    }

    @include media.desktop-only {
      width: 84%;
      height: 90px;
    }

    @include flex-vertical-center;

    justify-content: space-between;
    width: 70%;
    height: 100px;
    background-color: theme.$primary-background-color;

    .left {
      @include media.tablet-narrow {
        flex-grow: 1 !important;
        justify-content: space-between;
        margin: 0 20px 0 10px;
      }

      .menuIconWrapper {
        @include media.tablet-narrow {
          display: block;
          cursor: pointer;
        }

        display: none;
      }
    }

    .right {
      @include media.mobile {
        display: none;
      }

      @include media.tablet-narrow {
        display: none !important;
      }
    }

    .column {
      @include flex-vertical-center;

      gap: 40px;

      .reactLink {
        margin-right: 18px;

        @include media.mobile {
          margin-right: 12px;
        }

        @include flex-vertical-center;
      }

      .logoContainer {
        display: flex;
        align-items: center;

        .text {
          line-height: 128%;
          opacity: 0.9;
          transform: scale(1, 1.1);
        }
      }

      .logo {
        @include media.mobile {
          width: 70px;
          height: 70px;
        }

        width: 80px;
        height: 80px;
        cursor: pointer;
        border-radius: 50%;
      }

      .link {
        @include media.mobile {
          display: none;
        }

        position: relative;
        cursor: pointer;

        .linkContent {
          font-family: theme.$main-font-family;
          font-weight: 600;
          text-transform: uppercase;

          &:hover {
            color: theme.$branding-font-color;
          }
        }
      }

      .mailButton {
        @include mail-button;

        background-color: theme.$background-color-contact-button;
      }

      .hideForMobile {
        @include media.mobile {
          display: none;
        }
      }

      .menuDropdown {
        @include flex-column;
        @include z-index(dropdownmenu);

        position: absolute;
        margin-top: 20px;
        background-color: theme.$secondary-background-color;
        border-radius: 8px;
        box-shadow: theme.$main-box-shadow;
      }
    }
  }
}
