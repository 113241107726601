@use "../theme" as theme;
@use "../media" as media;
@use "./functions" as functions;

@mixin flex-horizontal-center {
  display: flex;
  justify-content: center;
}

@mixin flex-vertical-center {
  display: flex;
  align-items: center;
}

@mixin flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-horizontal-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flex-column-all-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin main-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  color: theme.$branding-font-color;
  background-color: theme.$background-color-contact-button;
  border: solid 1px #2f323f;
  border-radius: 8px;

  &:hover {
    color: #000;
    background-color: theme.$branding-font-color;
    border: solid 1px theme.$branding-font-color;
  }

  .customIcon {
    margin-right: 8px;
  }

  &:hover .customIcon {
    color: #000;
  }

  .text {
    font-size: 18px;
  }
}

@mixin menu-list-cell {
  width: 100%;
  padding: 20px;
  font-size: 16px;
  line-height: 1.3em;
  text-transform: uppercase;
  border-bottom: solid grey 1px;
}

@mixin mail-button {
  @include flex-horizontal-center;

  width: 172px;
  height: 60px;
  padding: 18px 35px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: theme.$branding-font-color;
  background-color: theme.$secondary-background-color;
  border-radius: 10px;

  &:hover {
    color: theme.$primary-font-color;
    background-color: theme.$branding-font-color;
  }
}

@mixin z-index($key) {
  z-index: functions.z-index($key);
}

@mixin standard-page-title {
  font-size: 42px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;

  @include media.tablet-wide {
    font-size: 26px;
  }
}

@mixin standard-page-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.85;
  color: theme.$primary-font-color;
  opacity: 0.8;

  @include media.tablet-wide {
    font-size: 16px;
    line-height: 1.45;
  }
}

@mixin background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.9;
}
