@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.courseBannerSection {
  display: flex;
  width: 100%;
  background-color: theme.$primary-background-color;

  .presentation {
    @include media.tablet-wide {
      flex-direction: column;
      gap: 28px;
    }

    @include flex-all-center;

    width: 90%;
    margin: auto;
    margin-top: 60px;

    .imageContaier {
      @include media.tablet-wide {
        order: 1 !important;
        width: 68% !important;
      }

      @include media.mobile {
        width: 100% !important;
      }

      display: flex;
      justify-content: center;
      width: 50%;

      .image {
        @include media.widescreen {
          width: 80%;
        }

        width: 100%;
        height: auto;
        border-radius: 14px;
      }
    }

    .textAndIconsContainer {
      display: flex;
      justify-content: center;

      @include media.tablet-wide {
        width: 68% !important;
        margin-top: 20px;
        margin-left: 0 !important;
      }

      @include media.mobile {
        width: 100% !important;
      }

      width: 50%;

      .textAndIcons {
        @include media.desktop {
          width: 100%;
          margin-top: 20px;
          margin-left: 0 !important;
        }

        width: 80%;
        max-width: 780px;
      }

      .name {
        @include standard-page-title;
      }

      p {
        @include media.tablet-wide {
          width: 100%;
        }

        @include standard-page-content;

        width: 100%;
      }

      .position {
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.85;
        color: #ddd;
      }

      .socialIcons {
        @include flex-horizontal-center;

        flex-direction: column;
        width: 100%;
        margin-top: 40px;

        .text {
          margin-bottom: 24px;
          color: theme.$branding-font-color;
        }

        .customIcon {
          color: theme.$branding-font-color;
        }

        .icons {
          display: flex;
          gap: 20px;
          justify-content: space-around;
        }

        .row {
          display: flex;
          gap: 28px;
          width: 100%;
        }
      }
    }
  }
}
