$mobile: 600px;
$tablet-narrow: 768px;
$desktop: 960px;
$widescreen: 1152px;
$short: 1200px;

@mixin short {
  @media screen and (height <= 1200px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin tablet-narrow {
  @media screen and (max-width: $tablet-narrow) {
    @content;
  }
}

@mixin tablet-narrow-up {
  @media screen and (min-width: $tablet-narrow) {
    @content;
  }
}

@mixin tablet-wide {
  @media screen and (max-width: ($desktop - 1px)) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $desktop) {
    @content;
  }
}

@mixin desktop-up {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: ($widescreen - 1px)) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-down {
  @media screen and (max-width: ($widescreen - 1px)) {
    @content;
  }
}
