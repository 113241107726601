@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.videoBannerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: theme.$primary-background-color;

  .videoBanner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: fit-content;
    min-height: 560px;
    pointer-events: none;

    @include z-index(videobanner);

    .iframeVideo {
      width: 100%;
      height: 100%;
    }
  }
}
