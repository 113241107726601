@use "~minireset.css/minireset.min";
@use "./media";
@use "./theme";
@import "./global";

a {
  color: inherit;
  text-decoration: none;
}

body {
  font-family: theme.$main-font-family;
  font-size: 16px;
  color: theme.$primary-font-color;
}
