@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.founderSection {
  @include media.mobile {
    margin: 30px 0;
  }

  width: 100%;
  margin: 60px 0;

  .presentation {
    @include media.tablet-wide {
      flex-direction: column;
    }

    @include flex-all-center;

    gap: 20px;
    width: 90%;
    margin: auto;

    .imageContaier {
      @include media.desktop-up {
        width: 100%;
      }

      @include media.desktop {
        width: 68% !important;
      }

      @include media.mobile {
        width: 100% !important;
      }

      display: flex;
      justify-content: center;
      width: 50%;

      .founderImage {
        @include media.widescreen {
          width: 80%;
        }

        @include media.mobile {
          width: 100%;
          margin: auto;
        }

        width: 100%;
        height: auto;
        border-radius: 14px;
      }
    }

    .textAndIconsContainer {
      @include media.desktop-up {
        width: 88%;
      }

      @include media.tablet-wide {
        width: 68% !important;
        margin-top: 20px;
        margin-left: 0 !important;
      }

      @include media.mobile {
        width: 100% !important;
      }

      width: 50%;

      .textAndIcons {
        @include media.desktop-only {
          width: 100%;
          margin-top: 20px;
          margin-left: 40px;
        }

        @include media.desktop {
          width: 100%;
          margin-top: 20px;
          margin-left: 0 !important;
        }

        width: 90%;
      }

      .name {
        @include standard-page-title;
      }

      p {
        @include media.tablet-wide {
          width: 100%;
        }

        @include standard-page-content;

        width: 100%;
      }

      .position {
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.85;
        color: #ddd;
      }

      .socialIcons {
        @include flex-horizontal-center;

        flex-direction: column;
        width: 100%;
        margin-top: 40px;

        .text {
          margin-bottom: 24px;
          color: theme.$branding-font-color;
        }

        .customIcon {
          color: theme.$branding-font-color;
        }

        .icons {
          display: flex;
          gap: 20px;
          justify-content: space-around;
        }

        .row {
          display: flex;
          gap: 28px;
          width: 100%;
        }
      }
    }
  }
}
