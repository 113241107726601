@use "#styles/theme" as theme;
@use "#styles/media" as media;

.appRoutesWrapper {
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
    min-height: theme.$min-vh-page-wrapper;
  }

  .whatsappButtonPosition {
    @include media.tablet-wide {
      position: fixed;
      top: unset;
      bottom: 0;
      width: 100%;
      transform: none;
    }

    position: fixed;
    top: 50%;
    right: 0;
    z-index: 5000;
    transform: rotate(90deg) translateY(-156%);
  }
}
