@use "#styles/theme" as theme;
@use "#styles/media" as media;
@import "#styles/helpers/mixins";

.linksRow {
  width: 100%;
  height: 86px;
  background-color: theme.$option-row-background-color;

  @include media.desktop {
    height: 90px;
  }

  @include flex-horizontal-center;

  ul {
    @include media.mobile {
      gap: 20px;
      width: 100%;
      padding: 0 20px;
    }

    @include flex-all-center;

    gap: 40px;
    width: 1200px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;

    .listItem {
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
